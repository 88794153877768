.header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #f6fbfe;
  position: relative;
}

.header img {
  width: 113px;
  height: 100px;
}

.aboveHeader {
  /* background-color: #0f5d9c; */
  background-color: #29368c;
  padding: 10px;
  color: white;
  font-weight: bold;
}

.openDrawer {
  position: absolute;
  left: 25px;
  top: 53.25px;
}

.openDrawer > svg {
  width: 40px;
  height: 40px;
}

.mobileHeader {
  display: none;
}

.header_icon {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header_icon > img {
  height: 38px;
}

.header_icon > h3, .header_icon > h4, .header_icon > p, .header_icon > a {
  margin-left: 10px;
}

.header_icon_drawer {
  margin-left: 10px;
  height: 74px;
}

a.contact__p {
  margin-top: 16px;
  margin-bottom: 16px;
}

.header_info {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
}

.footerHeader__logos img {
  width: 35px;
  cursor: pointer;
  background-color: #29368c;
  border-radius: 100px;
  border: 1px solid #29368c;
}

.footerHeader__logos a:first-child {
  margin-right: 10px;
}

.footerHeader__logos {
  height: 74px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.link {
  text-decoration: none;
  color: grey;
}

/* responsive header */
@media only screen and (max-width: 750px) {
  .noShowMobile {
    display: none;
  }

  .mobileHeader {
    display: flex;
  }

  .headerItem {
    border-bottom: 1px solid black;
    padding-left: 85px !important;
    padding-right: 85px !important;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}